<template>
  <div class="mobile-login">
    <!-- <div class="logo">
      <img src="../../assets/images/logoo.png" />
    </div>-->
    <!-- <navBar  :title="title"></navBar> -->

    <div class="image-container">
      <img alt src="../../assets/images/1791637026300_.pic_hd.jpg" />
    </div>

    <div class="login-container">
      <!-- 账号和密码登陆 -->
      <div class="login-number" v-if="checkNumber">
        <el-form :model="userInfo" ref="form">
          <el-form-item
            :rules="[
              { required: true, message: '请输入账号', trigger: 'blur' }
            ]"
            prop="variable"
          >
            <el-input placeholder="请输入账号" type="number" v-model="userInfo.variable"></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
              { required: true, message: '请输入密码', trigger: 'blur' }
            ]"
            prop="password"
          >
            <el-input :show-password="true" placeholder="请输入密码" v-model="userInfo.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="!checked" @click="numberLogin">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 手机号登陆 -->
      <div class="login-phone" v-else>
        <el-form :model="userInfo" ref="formPhone">
          <el-form-item
            :rules="[
              { required: true, message: '请输入手机号', trigger: 'blur' }
            ]"
            prop="variable"
          >
            <el-input placeholder="请输入账号" type="number" v-model="userInfo.variable"></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
              { required: true, message: '请输入验证码', trigger: 'blur' }
            ]"
            class="codes"
            prop="code"
          >
            <el-input placeholder="请输入验证码" type="number" v-model="userInfo.code"></el-input>
            <el-button :disabled="isDisabled" @click="sendCode">
              {{
              buttonName
              }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="!checked" @click="phoneLogin">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--忘记密码/注册 -->

      <div class="menu-footer">
        <span @click="checkPhoneLogin">{{ checkNumber ? '手机登录' : '账号登录' }}</span>
        <span @click="findNumber">账号找回</span>
        <span @click="register">注册</span>
      </div>

      <!-- <div class="forget">
        <span @click="showPopup">账号找回?</span>
        <span>|</span>
        <span @click="register">注册</span>
      </div>-->
      <!-- 忘记密码 -->
      <div class="forget-box">
        <van-popup v-model="show">
          <div class="modify">
            <div class="title">账号找回</div>
            <div class="step" v-if="step">
              <div class="modify-password">
                <span>公司名称</span>
                <span>:</span>
                <div>
                  <el-input placeholder="请输入公司名称" v-model="companyInfo.name"></el-input>
                </div>
              </div>
              <div class="modify-password">
                <span>公司税号</span>
                <span>:</span>
                <div>
                  <el-input
                    @input="inputs(companyInfo.creditCode)"
                    placeholder="请输入公司税号"
                    v-model="companyInfo.creditCode"
                  ></el-input>
                </div>
              </div>
              <div class="submit">
                <el-button @click="next" type="primary">下一步</el-button>
              </div>
            </div>
            <div class="step" v-else>
              <div class="modify-password">
                <span>修改账号</span>
                <span>:</span>
                <div>
                  <el-input placeholder="请输入修改账号" v-model="userInfo.loginPhone"></el-input>
                </div>
              </div>
              <div class="modify-password">
                <span>修改密码</span>
                <span>:</span>
                <div>
                  <el-input placeholder="请输入修改密码" v-model="userInfo.password"></el-input>
                </div>
              </div>
              <div class="submit">
                <el-button @click="submit" type="primary">确认</el-button>
              </div>
            </div>
          </div>
        </van-popup>
      </div>

      <div class="user-protocol">
        <van-checkbox icon-size="14px" shape="square" v-model="checked">
          阅读并同意以下协议《
          <span @click.stop="protocol(1)" class="color">用户服务协议</span>》及《
          <span @click.stop="protocol(2)" class="color">隐私条款</span>》
        </van-checkbox>
      </div>
      <!-- 其他方式登陆 -->
      <!-- <div class="xb-icon-bottom">
        <div class="xb-other">
     
        </div>
        <div class="xb-icon-phone">
          <div class="icon">
            <i class="el-icon-mobile-phone"></i>
          </div>
          <div @click="checkPhoneLogin" class="icon-name">{{ checkNumber ? '手机号登录' : '账号密码登录' }}</div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { Notify, Dialog } from 'vant';
import navBar from '../commonAssembly/navBar'

export default {
  name: 'mobileLogin',
  components: {
    navBar
  },
  data () {
    return {
      title: {
        name: '登录',
        status: false,
      },
      checkNumber: true,
      show: false,
      isDisabled: false,
      time: 60,
      buttonName: '发送验证码',
      companyInfo: {
        name: "",
        creditCode: ""
      },
      checked: true,
      userInfo: {
        id: "",
        loginPhone: "",
        password: "",
      },
      step: true,
      userInfo: {
        variable: "", // 用户名
        password: "", // 密码
        code: "",
        uuid: ""
      },
      userPhone: {
        phone: '',
        code: ''
      }
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    // 找回账号
    findNumber () {
      this.$router.push('/findNumber')
    },
    showPopup () {
      this.show = true;

    },
    inputs (str) {
      this.companyInfo.creditCode = str.replace(/(\w{4})(?=\w)/g, '$1 ').replace(/(\d{4})(?=\d)/g, '$1 ').toUpperCase();
    },
    protocol (index) {
      if (index == 1) {
        this.$router.push({
          name: 'serve',
          params: { status: true }
        })
      } else if (index == 2) {
        this.$router.push({
          name: 'serve',
          params: { status: false }
        })
      }

    },
    register () {
      this.$router.push('/register')
      // this.$parent.openRegisterPage();

      // this.$router.push('/register')
    },
    // 发送验证码
    sendCode () {
      if (this.userInfo.variable !== '') {
        let data = {
          phoneNumber: this.userInfo.variable
        }
        this.$request.get(this.$api.code, data).then(res => {
          console.log('code', res);
          this.$toast.success('发送验证码成功');
          this.isDisabled = true;
          let _this = this;
          var interval = window.setInterval(function () {
            _this.buttonName = "已发送" + _this.time;
            --_this.time;
            if (_this.time < 0) {
              _this.buttonName = "重新发送";
              _this.time = 60;
              _this.isDisabled = false;
              window.clearInterval(interval);
            }
          }, 1000);
        }).catch(err => {
          console.log(err, "失败的验证码");
          this.$toast('发送验证码失败');
        })
      }
    },
    // 账号密码登陆
    numberLogin () {
      console.log('9090990090', this.userInfo.code);
      // if (this.checked == false) {
      //   Dialog.alert({
      //     title: '标题',
      //     message: '请阅读并同意以下协议后方可登录',
      //   }).then(() => {
      //     // on close
      //   });
      // }


      if (this.userInfo.code !== '') {
        this.userInfo.code = ''
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.commonLogin('密码')
        }
      })
    },
    //手机号登陆
    phoneLogin () {
      this.$refs.formPhone.validate(valid => {
        if (valid) {
          this.commonLogin('验证码')
        }
      })
    },
    //公告登陆
    commonLogin (text) {
      this.$request.post(this.$api.login, this.userInfo).then(res => {
        console.log('res', res);

        window.sessionStorage.setItem("user-token", JSON.stringify(res.data));
        this.$store.state.userId = res.data.enterpriseUserDTO.id;
        sessionStorage.setItem("user-id", JSON.stringify(res.data.enterpriseUserDTO.id))
        this.$store.state.tokenStatus = true;
        sessionStorage.setItem("tokenStatus", true)
        // this.$parent.closeLoginPage()closeLoginPage
        // location.reload()
        this.$toast.success('登陆成功');
        this.$router.push('/')
        window.location.reload();

      }).catch(err => {
        this.$toast(err.response.data.message);
      })
    },
    //修改密码
    next () {
      var url = this.$api.checkUserInfo;
      if (this.companyInfo !== "")
        this.$request.post(url, this.companyInfo).then(res => {
          if (res.status == 200 && res.data != "") {
            this.step = false;
            console.log('reeeee', res);
            this.userInfo.id = res.data;
            this.userInfo.password = ''
          } else {
            Notify({ type: 'danger', message: '请输入正确的公司名称/税号，如忘记请联系管理人员!' });
          }
        });

    },
    submit () {
      if (this.userInfo !== "") {
        this.$request.put(this.$api.amendNumber, this.userInfo).then(res => {
          // 17701246400
          if (res.status == 200) {
            Notify({ type: 'success', message: '修改成功，请重新登录！' });
            this.$router.push("/mobileIndex");
            sessionStorage.removeItem('user-token')
            sessionStorage.clear()
            window.location.reload()
          }
        });
      }
    },
    checkPhoneLogin () {
      this.checkNumber = !this.checkNumber;
      this.userInfo.code = ''
    }
  }
}
</script>


<style scoped lang="less">
.mobile-login {
  // height: 100vh;
  padding: 0 30px 30px 30px;

  .image-container {
    width: 100%;
    height: 75px;
    margin: 50px 0px;
    position: relative;
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 195px;
      height: 60px;
    }
  }
  .logo {
    // width: 225px;
    // margin: 70px auto;
    margin-bottom: 35px;
    img {
      width: 100%;
    }
  }
  /deep/.van-cell {
    border-radius: 30px;
  }
  .login-container {
    /deep/.el-input__inner {
      // border-radius: 50px !important;
    }

    .forget {
      margin-top: 20px;
      color: #b8b0b0;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    .forget-box {
      .modify {
        width: 300px;
        height: 300px;
        border-radius: 5px !important;
        > .title {
          width: 100%;
          height: 65px;
          margin-bottom: 30px;
          line-height: 65px;
          text-align: center;
          color: #000;
          font-size: 20px;
        }
        .modify-password {
          padding: 0 25px;
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          justify-content: space-around;
          > span {
            color: #000;
            font-size: 16px;
          }
          /deep/.el-input__inner {
            width: 160px;
            border-radius: 3px !important;
            height: 38px;
          }
        }
        .submit {
          display: flex;
          justify-content: center;
          margin-top: 55px;
          padding: 0 25px;
          /deep/.el-button {
            width: 80px;
            border-radius: 3px !important;
          }
        }
      }
    }
    .xb-icon-bottom {
      .xb-other {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #b8b0b0;
        margin-top: 65px;
        > div {
          &:nth-child(1) {
            width: 70px;
            border-top: 1px solid #cccccc;
          }
          &:nth-child(3) {
            width: 70px;
            border-top: 1px solid #cccccc;
          }
        }
      }
      .xb-icon-phone {
        display: flex;
        margin-top: 35px;
        color: #b8b0b0;
        flex-direction: column;
        align-items: center;
        /deep/.el-icon-mobile-phone {
          font-size: 25px;
        }
        .icon-name {
          margin-top: 10px;
        }
      }
    }
  }
}
/deep/.el-input__inner {
  width: 100%;
  // border-radius: 50px;
}
/deep/.el-button {
  width: 100%;
  // border-radius: 50px;
  background-color: #ecf5ff;
  color: #3a8ee6;
}

.codes {
  /deep/.el-form-item__content {
    display: flex;

    .el-input {
      width: 180px !important;
      > .el-input__inner {
        width: 160px;
      }
    }
    > .el-button {
      width: 100px;
      padding: 0;
    }
  }
}

.menu-footer {
  display: flex;
  justify-content: space-between;
  color: #b8b0b0;
  margin-top: 80px;
}
.user-protocol {
  position: fixed;
  bottom: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #b8b0b0;

  /deep/.van-checkbox__label {
    color: #b8b0b0;

    .color {
      color: #1989fa;
    }
  }
}
</style>


